















import {Vue, Component} from 'vue-property-decorator';

@Component
export default class LayoutSystemBar extends Vue {
  private get env(): string | null {
    return process.env.VUE_APP_ENV || null;
  }

  private get envColor(): string | null {
    return this.env === 'STAGE' ? 'primary' : null;
  }
}
